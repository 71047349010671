import React from "react";
import "./src/styles/global.css";
import { AppProvider } from "./src/context/AppContext";
import { Provider } from "./src/components/ui/provider";
import { ChakraProvider } from "@chakra-ui/react";

export const wrapRootElement = ({ element }) => {
  return (
    // {/* <AppProvider> */}
    <Provider>{element}</Provider>
    // {/* </AppProvider> */}
  );
};
